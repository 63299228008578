.header{
    display: flex;
    flex-direction: column;
    background-color: #476c9b;
    padding-bottom: 10px;
}

.label-container{
    display: flex;
    justify-content: center;
}

.label-container > img{
    width: 50px;
    height: 50px;
}

.label-container > img:hover{
    cursor: pointer;
}

.search-container{
    margin: 6px 16px 0px;
    position: relative;
    display: flex;
}

.search-input{
    height: 15px;
    width: 100% ;
    border: none;
    padding: 10px 12px;
    font-size: 18px;
    border-radius: 4px 0px 0px 4px;
}

.search-button{
    width: 54px;
    position: relative;
    text-align: center;
    border: none;
    border-radius: 0px 4px 4px 0px;
    height: 35px;
    background-color: #bfc0c0;    
}
.search-button:hover{
    cursor: pointer;
    outline: -webkit-focus-ring-color auto 1px;
}

.search-input-error{
    height: 25px;
    width: 100% ;
    border: none;
    padding: 10px 12px;
    font-size: 18px;
    border-radius: 4px 0px 0px 4px;
    border: 1px inset red;
    animation: shake .75s; 
} 

@media (min-width: 600px){
    .header{    
        flex-direction: row;
        justify-content: space-between;
    }
    
    .search-container{
        margin: 5px 5px 0px;
    }
}