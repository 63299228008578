.form-container{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.title-container{
    display:flex;
    margin-bottom: 10px;
    margin-top: 5px;
}

.form-title:focus{
    outline: none;
    box-shadow: 0 0 2px 1px #476c9b;
}

 .tile-container:focus-within > * {
    outline: none;
    box-shadow: 0 0 2px 1px #476c9b;
    
}

.form-title{
    height: 25px;
    width: 100%; 
    padding: 10px 12px;
    font-size: 18px;
    border: 1px inset #000000;
    border-radius: 4px;
    text-align: center;
}

.tile-container{
    display: flex;
    position: relative; 
    margin-bottom: 2px;   
}
.tile-section{
    display: grid;
    grid-template-columns:  1fr;
    grid-column-gap: 5px;
    margin-bottom: 10px;

}

.form-tile{
    height: 15px;
    width: 100%; 
    padding: 10px 12px;
    font-size: 18px;
    border-radius: 0px 4px 4px 0px;
    border: 1px inset #000000;
    text-indent: 5%;
}

.form-tile-label{
    min-width: 21px;
    position: relative;
    text-align: center;

    border: 1px inset #000000;
    border-radius: 4px 0px 0px 4px;
    background-color: #e7ecef;
}

.form-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-button{
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: #bfc0c0;
}
.form-button:focus{
    box-shadow: 0 0 2px 1px #111218;
    outline:none;
}

.form-button:hover{
    cursor: pointer;
    box-shadow: 0 0 2px 1px #111218;
}
.form-title-error{
    height: 25px;
    width: 100%; 
    padding: 10px 12px;
    font-size: 18px;
    border: 1px inset #c32f27;
    border-radius: 4px;
    text-align: center;
}
.form-tile-error{
    height: 15px;
    width: 100%; 
    padding: 10px 12px;
    font-size: 18px;
    border-radius: 0px 4px 4px 0px;
    border: 1px inset #c32f27;
    text-indent: 5%;
}

.form-tile-label-error{
    min-width: 21px;
    position: relative;
    text-align: center;

    border: 1px inset #c32f27;
    border-radius: 4px 0px 0px 4px;

    animation: fill-color 1s;
    background-color: #c32f27;
}

@keyframes fill-color{
    from {background-color: lightgray;}
    to {background-color: #c32f27;}
}
@keyframes shake { 
    0%, 100% {transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);} 
    20%, 40%, 60%, 80% {transform: translateX(10px);} 
 }

.form-error{
    margin: 10px 0px;
    padding: 12px;
    color: #c32f27;
    background-color: #ffd2d2;
    border-radius: .5em;
    border: 1px solid;
    box-shadow: 1px 1px 3px #888;
    text-align: center;
    animation:  shake .75s; 
}

@media (min-width: 600px){
    .tile-section{
        grid-template-columns: 1fr 1fr;
    }

    .form-container{
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;

    }


    .form-title{
        text-align: center;
    }
}