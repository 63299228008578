.bingo-card{
    display: grid;
    /*grid-template-rows: 1fr 6fr 1fr;*/
    /*margin: 0px 10px 0px 10px;*/
}
.bingo-label{
    display: grid;
    grid-template-columns: repeat(5, minmax(0,1fr));
    background-color: #c32f27;
    text-align: center;
    font-size: 70px;
    font-family: sans-serif;
}
.bingo-label-item{
    border: 1px inset #000000;
}
.tt-container{
    display: grid;
    grid-template-rows: 1fr 6fr;
}
.title-container{
    justify-content: center;
    font-size: 19px;
    font-family: sans-serif;
}
.tiles-container{
    --columns: repeat(5, minmax(0,1fr));
    --rows: repeat(5, minmax(0,1fr));
    display: grid;
    grid-template-columns: var(--columns);
    grid-template-rows: var(--rows);
    overflow-wrap: break-word;
}
 .tiles-container div::before{
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
}
.free-space{
    border: 1px inset #000000;
    background-color: #ff8c00;
    text-align: center;
    padding-top: 30px;
    font-family: sans-serif;
    font-size: 20px;
}
 .tile-item{
    border: 1px inset #000000;
    text-align: center;
    font-family: sans-serif;
 }
 .card-button{
    height: 40px;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    background-color: #bfc0c0;
}
.card-button:focus{
    outline: none;
    box-shadow: 0 0 2px 1px #111218;
}

.card-button:hover{
    cursor: pointer;
    box-shadow: 0 0 2px 1px #111218;
}

.status-bar{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2px;
    margin-bottom: 2px;
}   

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.card-modal-view::before{
    overflow: hidden;
}
.card-modal-view > *{
    background-color: rgba(255, 255, 255, 0);
}

.card-gs-message{    
    cursor: default;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 70%;
    width: 50%;
    margin: auto;
    display: block;
    text-align: center;
    position: relative;
}

.card-gs-message > span {
    position: relative;
    top: 20px;
    display: inline-block;
    animation: bounce 0.3s ease infinite alternate;
    font-size: 10vw;
    color: #fff;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.card-gs-message > span:nth-child(2){
    animation-delay: 0.1s;
}
.card-gs-message > span:nth-child(3){
    animation-delay: 0.2s;
}

.card-gs-message > span:nth-child(4){
    animation-delay: 0.3s;
}

.card-gs-message > span:nth-child(5){
    animation-delay: 0.4s;
}

.card-gs-message > span:nth-child(6){
    animation-delay: 0.5s;
}

.card-gs-message > span:nth-child(7){
    animation-delay: 0.6s;
}

.card-gs-message > span:nth-child(8){
    animation-delay: 0.7s;
}

@keyframes bounce{
    100%{
        top: -20px;
        text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc, 0 50px 25px rgba(0, 0, 0, 0.2);
    }
}

@media(min-width: 600px){
    .bingo-card{
        width: 500px;
        grid-template-rows: 50px 590px 40px;
        /*height: 600px;*/
        margin: 0px auto 0px auto;
    }
    .card-gs-message{
        top: 35%;
        bottom: 0;

    }
}